<template>
      
  <section class="dashboard">
    
    <router-link class="rounded-button float-right bg-gradient-primary" :to="link('/create')">+</router-link>
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> {{$options.label }}</h3>
        
        <!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        -->
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listando {{$options.label}}</h4>
            <div class="table-responsive">


              <table class="table">
                <thead>
                  <tr>
                    <th><input type="checkbox"></th>
                    <th> ID </th>
                    <th> Obra </th>
                    <th> Nombre del cliente </th>
                    <th> Unidad </th>
                    <th>Precio</th>
                    <th>Precio de venta</th>
                    <th> Acciones </th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-if="listing">
                    <td colspan="10">
                      <b-skeleton-table
                        :rows="3"
                        :columns="4"
                        :table-props="{ bordered: true, striped: true }"
                        
                      ></b-skeleton-table>
                    </td>
                  </tr>


                  <tr v-for="(item, index) in items" :key="index">
                    <td><input type="checkbox"></td>
                    <td>{{item.id}}</td>
                    <td>{{ item.development.name }}</td>
                    <td>{{ item.client.name }}</td>
                    <td>{{ item.property.code }}</td>
                    <td>{{ $numberFormat(item.catalog_price) }}</td>
                    <td>{{ $numberFormat(item.sale_price) }}</td>
                    <td>
                      
                      <a class="action-link" @click="showPayments(index)"><i class="mdi mdi-history"></i></a>
                    </td>
                  
                  </tr>





                </tbody>
              </table>

              <b-modal ref="modalPayments" id="payments-modal" size="md" title="Estado del crédito" :hide-footer="true">

                  <div class="table-responsive">

                    <table class="table">

                        <thead>
                          <th>No. de pago</th>
                          <th>Tipo</th>
                          <th>Cantidad</th>
                          <th>Estado</th>
                          <th>Fecha límite</th>
                        </thead>

                        <tbody>

                          <tr v-for="(item,index) in getPayments" :key="index">
                            <td>{{ index+1 }}</td>
                            <td>{{getPaymentType(item.payment_type)}}</td>
                            <td>{{ $numberFormat(item.amount) }}</td>
                            <td>{{ item.completed==1?'Completado':'Por pagar' }}</td>
                            <td>{{ $customDate(item.due_date.split(' ')[0]) }}</td>

                          </tr>


                        </tbody>


                    </table>

                  </div>

              </b-modal>




            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import api from '@/util/api.js';


export default {
  routeName:'credits',
  label:'Créditos',
  name: 'listCredits',
  components: {
  },
  data() {
    return {
      items:[],
      listing:false,
      payments:[]
     
    };
  },

  computed:{

    getPayments(){

      return this.payments;


    }

  },

  methods: {

    getPaymentType(type){
        if(type=='down'){
          return 'Enganche';
        }
        return 'Abono';
    },


    getItems() {

      this.listing=true;
      
      api.get(this.me('?expand=development,client,property,documents,payments&filter[payment_option]=credit')).then(response => {
        this.items = response.data;

        
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

        this.listing=false;

      });
    },

    showPayments(index){

      this.payments=this.items[index].payments;
      this.$refs.modalPayments.show();

    }

      
  },

  mounted(){

    
    this.getItems();
  }

}



</script>


